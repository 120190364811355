import React, {useState} from 'react';
import Nb from './Navbar/NB'
import "@fontsource/roboto-condensed"
import "@fontsource/bitter/400.css"
import '../styles/global.css'
import MobileSide from './Navbar/mobileSide';

const Layout = ({ children }) => {

    const [isOpen, setIsOpen] = useState(false);
    
    const toggleOpen = () =>{
        setIsOpen(!isOpen);
    }

    return (
        <div className="layout">
            <MobileSide isOpen={isOpen} toggleOpen={toggleOpen} />
            <Nb toggleOpen={toggleOpen} />
            {children}
        </div>
    );
}

export default Layout;
