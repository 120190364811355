import React, { useState } from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import * as styles from '../styles/project.module.css'
import Popup from "./projects/Popup"

import androidPDF from './projects/videos/AndroidPDF.mp4'
import copsAndRobs from './projects/videos/CandR.mp4'
import sketchApp from './projects/videos/SketchIt.mp4'
import snakeGame from './projects/videos/Snake.mp4'
import pd from './projects/videos/pd.mp4'
import personalSite from './projects/images/personal.png'
import alexaImg from './projects/images/alexa.png'


const projects = [
    {
        title: 'Desktop Drawing App',
        media: sketchApp,
        isVideo: true,
        bodyText: "Cross platform desktop drawing/paint app (Java-based), featuring shapes, lines, save/import, copy/paste, custom colors and styling.",
        toolsSkills: "Java, JavaFX, Gradle, Object Oriented Programming, User Interface Design, IntelliJ IDEA."
    },
    {
        title: 'Mobile PDF Editor App',
        media: androidPDF,
        isVideo: true,
        bodyText: "Android PDF Editor app featuring touch-based zoom/pan, highlighting and markup, stroke erase, and undo/redo functionality.",
        toolsSkills: "Android app development, Java, Gradle, Android Studio."
    },
    {
        title: 'Voice PD',
        media: pd,
        isVideo: true,
        bodyText: "Collaborated with medical researchers to design and implement a custom web app to record user's voice samples & disease status, used for machine learning-based Parkinson's Disease diagnosis research.",
        toolsSkills: "React, Firebase, JavaScript, Node.js."
    },
    {
        title: 'Alexa Mood Journal',
        media: alexaImg,
        isVideo: false,
        hasBodyLink: true,
        bodyText: "Collaboratively developed an Amazon Alexa Skill, conversationally logging user's mood, habits, medication adherence, and activities. Built as a team effort during a hackathon, addressing the ongoing mental health crisis among young adults by allowing user-authorized medical professionals & family to review and track the collected data. Check out a ", 
        bodyLinkText: "quick demo one of my fellow teammembers posted on Devpost!",
        toolsSkills: "AWS (Lambda, DynamoDB), Node.js."
    },
    {
        title: 'Cops & Robbers',
        media: copsAndRobs,
        isVideo: true,
        bodyText: "Designed and programmed a two player 2D video game based on a creative original concept, titled 'Cops and Robbers.' Features multiplayer game design, collision detection, and custom graphics & controls.",
        toolsSkills: "JavaScript, jQuery, HTML5 Canvas, CSS."
    },
    {
        title: 'Java Snake',
        media: snakeGame,
        isVideo: true,
        bodyText: "Cross platform desktop video game (Java-based), featuring multiple levels & progression, graphics, sound, collision detection, and scoring. Made use of object oriented programming principles/best practices for multi-level and game entity implementation.",
        toolsSkills: "Java, Object Oriented Programming, JavaFX, Gradle, IntelliJ IDEA."
    },

    {
        title: 'Personal Portfolio Site',
        media: personalSite,
        isVideo: false,
        bodyText: "The site you're currently on!",
        toolsSkills: "React, Gatsby, HTML/CSS, Hosting."
    },
]

const Projects = () => {

    const mouseIn = (proj) => {
        if (proj.isVideo) {
            var video = document.getElementById(proj.title);
            var isPlaying = video.currentTime > 0 && !video.paused && !video.ended
                && video.readyState > video.HAVE_CURRENT_DATA;
            if (!isPlaying) {
                video.play();
            }
        }
    }

    const mouseOut = (proj) => {
        if (proj.isVideo) {
            var video = document.getElementById(proj.title);
            var isPlaying = video.currentTime > 0 && !video.paused && !video.ended
                && video.readyState > video.HAVE_CURRENT_DATA;
            if (isPlaying) {
                video.pause();
            }
        }
    }

    const handleClick = (proj) => {

        setShowPopup(prevState => !prevState);
        setClickedProj(proj);
    }

    const [showPopup, setShowPopup] = useState(false);
    const [clickedProj, setClickedProj] = useState(null);

    return (
        <section className="section" >
            <Popup setShowPopup={handleClick} showPopup={showPopup} proj={clickedProj} />
            <a className="sectionAnchor" id="projects"></a>
            <div className={styles.projectPageContainer}>
                <h2 className={styles.header}>Recent Personal Projects</h2 >


                <div className={styles.projectTilesContainer}>
                    {
                        projects.map(proj => (
                            <div key={proj.title} className={styles.projectCard}
                                onMouseOver={() => mouseIn(proj)}
                                onMouseOut={() => mouseOut(proj)}
                                onClick={() => handleClick(proj)}
                            >
                                {proj.isVideo ? <video
                                    key={proj.title}
                                    id={proj.title}
                                    muted loop playsInline
                                    preload="metadata"
                                    className={styles.media}
                                    style={{ objectFit: "cover" }}
                                >
                                    <source src={proj.media + (proj.title == 'Mobile PDF Editor App' ? "#t=43" : "#t=23")} type="video/mp4" />
                                </video>
                                    : <img
                                        src={proj.media}
                                        alt={proj.title}
                                        className={styles.media}
                                        style={{ objectFit: "contain" }}
                                    />
                                }
                                <div className={styles.text}>
                                    <div style={{margin:"0.5em"}}>{proj.title}</div>
                                    <div><FaLongArrowAltRight style={{ color: 'white' }} /></div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

export default Projects;
