import React from 'react';
import * as styles from '../styles/more.module.css'
import { StaticImage } from "gatsby-plugin-image"

const More = () => {
    return (
        <section className="section">
            <a className="sectionAnchor" id="more"></a>
            <div className={styles.moreContainer}>
                <h1 className={styles.header}>Links & Contact</h1>

                <div className={styles.moreBodyContainer}>
                    <p style={{ fontSize: "1.6rem" }}>Please feel free to <a href="mailto:r2habibi@uwaterloo.ca">reach out</a> if you're interested in my résumé, project code, or anything else.
                    <br/> <br/> 
                    You can also find me on <a href="https://github.com/Habibi-R" target="_blank" rel="noopener noreferrer">GitHub</a> and <a href="https://devpost.com/r2habibi" target="_blank" rel="noopener noreferrer">Devpost</a>.
                    <br/> <br/>   <br/> <br/> 
                    </p>


                        <StaticImage
                            src="../images/endImage.JPG"
                            alt="me"
                            placeholder="blurred"
                            objectFit="contain"
                            className={styles.endImage}
                        />

                </div>

            </div>
        </section>
    );
}

export default More;
