import React from 'react';
import * as styles from '../styles/about.module.css'
import { StaticImage } from "gatsby-plugin-image"

const AboutIntro = () => {
    return (
        <section className="section">
            <a className="sectionAnchor" id="about"></a>

            <div className={styles.aboutContainer}>

                <div className={styles.left}>
                    <div className={styles.nameInfo}>
                        <h1>Rodin Habibi</h1>
                        <h3>Software Developer</h3>
                    </div>

                    <div className={styles.bodyText}>
                        <p style={{ paddingTop: '0.1em' }}>
                            Computer Science student at the University
                            of Waterloo, class of 2023.
                        </p>
                        <p>
                            In my co-ops and spare time,
                            I've built web, mobile, and desktop apps. 
                            I've had industry experience as a full-stack, front-end, and bioinformatics developer.
                        </p>
                        
                        <p>
                            Feel free to <a href="mailto:r2habibi@uwaterloo.ca">contact me</a>,
                            or keep scrolling to see some of my personal projects.
                        </p>
                    </div>
                </div>

                <div className={styles.right}>
                    <StaticImage
                        src="../images/me.png"
                        alt="me"
                        placeholder="blurred"
                        objectFit="contain"
                        className={styles.myImg}
                    />
                </div>
            </div>
        </section>
    );
}

export default AboutIntro;
