import React from "react"
import Layout from "../components/Layout"
import AboutIntro from "../components/aboutIntro"
import Projects from "../components/projects"
import More from "../components/More"
import { Helmet } from "react-helmet"

export default function Home() {
  return (
    <div className="sectionsContainer">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rodin Habibi</title>
      </Helmet> 
      <Layout>
        <AboutIntro />
        <Projects />
        <More />
      </Layout>
    </div>

  )
}
