import React from 'react'
import { Link } from "gatsby"
import {FaTimes} from 'react-icons/fa';

const MobileSide = ({isOpen, toggleOpen}) => {
    return ( 
        <div className='mobileSideContainer'
            style={{
                top: isOpen ? '0' : '-100%',
                opacity: isOpen ? '96%' : '0'
            }}
        >
            <div onClick={toggleOpen} className='mobileCloseIconContainer'>
                <FaTimes style={{color: "black"}}/>
            </div>

            <div className='mobileSideLinks'>
                <Link onClick={toggleOpen} to="/#about"><h3>About Me</h3></Link>
                <Link onClick={toggleOpen} to="/#projects"><h3>Projects</h3></Link>
                <Link onClick={toggleOpen} to="/#more"><h3>Links & Contact</h3></Link>
            </div>
        </div>
     );
}
 
export default MobileSide;
