import React from 'react';
import { Link } from "gatsby"
import {FaBars} from 'react-icons/fa'

const Nb = ({toggleOpen}) => {
    return ( 
        <nav>
            <Link style={{paddingLeft: '1em'}}to="/#about"><h1>RH</h1></Link>

            <div onClick={toggleOpen} className="mobileMenu">
                <FaBars/>
            </div>

            <div className="navLinks">
                <Link to="/#about"><h3>About Me</h3></Link>
                <Link to="/#projects"><h3>Projects</h3></Link>
                <Link to="/#more"><h3>Links & Contact</h3></Link>
            </div>
        </nav>
     );
}
 
export default Nb;
