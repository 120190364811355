import React, { useRef, useEffect, useCallback } from 'react';
import * as styles from '../../styles/projectPopup.module.css'
import { FaTimes } from 'react-icons/fa';

const Popup = ({ showPopup, setShowPopup, proj }) => {

    const popupRef = useRef();

    const exitPopup = e => {
        if (popupRef.current === e.target) {
            setShowPopup();
        }
    }

    const escapeHandle = useCallback(e => {
        if (e.key === 'Escape' && showPopup) {
            setShowPopup()
        }
    }, [setShowPopup, showPopup])

    useEffect(() => {
        document.addEventListener('keydown', escapeHandle);
        return () => document.removeEventListener('keydown', escapeHandle)
    }, [escapeHandle])

    return (
        <>{showPopup ?
            <div ref={popupRef} onClick={exitPopup} id={styles.modalBackground}>
                <div id={styles.modalContainer}>
                    <div >
                        <FaTimes className={styles.mobileCloseIconContainer} onClick={setShowPopup} style={{ color: ' rgb(0, 0, 0)', float: 'right' }} />
                    </div>

                    <div className={styles.mediaContainer}>
                        {proj.isVideo ? <video
                            className={styles.media}
                            controls
                        // style={{ objectFit: "cover" }}
                        >
                            <source src={proj.media} type="video/mp4" />
                        </video>
                            : <img
                                src={proj.media}
                                alt={proj.title}
                                className={styles.media}
                            // style={{ objectFit: "contain" }}
                                style={ proj.title == 'Alexa Mood Journal' ? {maxHeight:"300px"}:null }
                            />
                        }
                    </div>
                    <div className={styles.bodyContainer}>
                        <h1>{proj.title}</h1>
                        <p>{proj.bodyText}
                            {proj.hasBodyLink ? <a href="https://devpost.com/software/alexa-mood-journal" target="_blank" rel="noopener noreferrer">
                                {proj.bodyLinkText}</a>
                                : null}</p>
                        <h3>Tools/Skills Used</h3>
                        <p style={{marginBottom: "0px"}}>{proj.toolsSkills}</p>
                    </div>
                </div>
            </div>
            : null
        }
        </>
    );
}

export default Popup;
